<template>
    <component
        :is="element"
        :id="id"
        :name="name"
        :class="buttonClass"
        :disabled="disabled || loading"
        :href="isLink ? (href ? href : '#') : null"
        :role="isLink ? 'button' : null"
        :type="isLink ? null : type"
        @click.stop="$emit('click')"
    >
        <div v-if="loading" :class="spinnerClass" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <IconLoader v-else :icon="icon" :size="size" />
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { buttonSizeOptions, buttonTypeOptions, buttonVariantOptions, iconOptions } from '../../mocks/StorybookOptions.mock'
import IconLoader from '../icons/IconLoader.vue'

export default defineComponent({
    name: 'IconButton',
    components: {
        IconLoader,
    },
    props: {
        icon: {
            type: String,
            required: true,
            validator: (value: string) => iconOptions.includes(value),
        },
        name: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'button',
            validator: (value: string) => buttonTypeOptions.includes(value),
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => buttonSizeOptions.includes(value),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: 'primary',
            validator: (value: string) => buttonVariantOptions.includes(value),
        },
        id: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasBorder: {
            type: Boolean,
            default: true,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        hasHover: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        element() {
            return this.href ? 'a' : 'button'
        },
        buttonClass() {
            return {
                btn: true,
                [`btn-${this.variant}`]: !!this.variant,
                [`btn-${this.size}`]: !!this.size,
                'border-0': !this.hasBorder,
                'bg-transparent': this.transparent,
                'no-hover': !this.hasHover,
            }
        },
        spinnerClass() {
            return {
                'spinner-border': true,
                [`spinner-border-${this.size}`]: !!this.size,
            }
        },
        isLink() {
            return this.href !== null
        },
    },
})
</script>

<style scoped lang="scss">
svg,
.b-icon.bi {
    display: block;
}

.btn {
    padding: 0.375rem;

    &-tiny {
        padding: 0 0.075rem;
        width: 1.25rem;
        height: 1.125rem;
    }

    &-xs {
        padding: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    &-sm {
        padding: 0.25rem;
    }

    &-lg {
        padding: 0.5rem;
    }
}

.spinner-border {
    display: block;
    width: 1.5rem;
    height: 1.5rem;

    &-sm {
        width: 1.25rem;
        height: 1.25rem;
    }

    &-lg {
        width: 1.875rem;
        height: 1.875rem;
    }
}
</style>
